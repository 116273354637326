import { fork, actionChannel, put, take, call, race } from 'redux-saga/effects';
import * as actionTypes from './action'
import React from 'react'
import settings from '../settings'

/**
 * Saga watchers
 */
export function *watchGetClips() {
    let payload;
    const reqChannel = yield actionChannel(actionTypes.GET_CLIPS_REQUEST)
    while (payload = yield take(reqChannel)) {
      console.log('......................................................................IN WATCHGETCLIPS.......................')
        yield fork(getClips, payload);
    }
}

/*
export function *watchSyncClip() {
    let payload;
    const reqChannel = yield actionChannel(actionTypes.CLIP_SYNC_REQUEST)
    while (payload = yield take(reqChannel)) {
        yield fork(syncClip, payload);
    }
    */

/**
 * Get clips 
 * @param {*} authToken 
 * @param {*} groupId 
 * @param {*} page 
 */
const httpGetClips = (payload) => { 


    let domain = settings.mode === "dev" ? "portal.joobster.at" : window.location.hostname

    
    if ( payload && payload.hasOwnProperty('authToken'))
   return fetch(settings.api_base_path + settings.portalpath + '/clip?domain=' + domain ,//+ window.location.hostname, 
    {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer ' + payload.authToken 
        }
    })
    .then((response) => response.json())
    .then((response) => response);

    else 
    return fetch(settings.api_base_path + settings.portalpath + '/clip?domain=' +  domain, // window.location.hostname, 
    {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        }
    })
    .then((response) => response.json())
    .then((response) => response);

    

};


const httpGetSections = (payload) => { 
  console.log('in sage httpgetclips with payload: ', payload)
  
  
 return fetch(settings.api_base_path + '/section', 
  {
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',
          'Authorization' : 'Bearer ' + payload.authToken
      }
  })
  .then((response) => response.json())
  .then((response) => response);
};

/*
const httpPutClip = (authToken, groupId, clip) => {
    return fetch(settings.api_base_path + settings.api_admin_path + '/group/' + groupId +  '/clip/' + clip.id, 
    {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer ' + authToken
        },
        body:JSON.stringify(clip)
    })
    .then((response) => response.json())
    .then((response) => response);
};
*/



function *getClips(action) {


    try {

        let result = yield call(httpGetClips, action.payload);
      //  let resultsections = yield call(httpGetSections, action.payload);

        if (result.status == 'ok') {
        //    console.log('::::::::::::::::::::::::::::::::::CLIPS GETTER OK:::::::::::::::::::::::::::::::::::', result, resultsections)

            yield put({ type: actionTypes.GET_CLIPS_SUCCESS, payload: { clips: result.clips, advertisingclips: result.advertising, sections: result.sections, settings:result.settings, status: result.status } });
        } else {
            // authorization error            
                console.log('::::::::::::::::::::::::::::::::::CLIPS GETTER trouble - auth required:::::::::::::::::::::::::::::::::::', result.settings);
            
            yield put({ type: actionTypes.CLIPS_ERROR, payload: {status: result.status, error: true, settings:result.settings }});

        }
    } catch(error) {
        console.log('::::::::::::::::::::::::::::::::::CLIPS GETTER mega error:::::::::::::::::::::::::::::::::::')

        yield put({ type: actionTypes.CLIPS_ERROR, payload:  {error: error }});


    }

}

/*
function *syncClip(action) {
    try {
        let result = yield call(httpPutClip, action.payload.authToken, action.payload.groupId, action.payload.clip);
        if (result.status == 'ok') {
            yield put({ type: actionTypes.CLIP_SYNC_RESPONSE, payload: { clip: result.clip } });

        } else {
            // authorization error
        }
    } catch(error) {
        yield put({ type: actionTypes.CLIPS_ERROR, payload: error, error: true });
    } 
}
*/


