import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as action from '../../../redux/action'


class Footer extends Component {

  render() {

    return (
      <div>


        <div style={{ backgroundColor: this.props.settings.primaryColor}} className="footer_container">
          <div className="footer clearfix">

            <div className="row copyright_row">
              <div className="column column_2_3">
                © Copyright <a href={this.props.settings.footerWebUrl} title="Zu unserem Webauftritt" target="_blank">Zu unserem Webauftritt</a> - Videoportal <a href={"mailto:" + this.props.settings.footerMailTo} title="Videoportal Komplettlösung" target="_blank"> Kontakt aufnehmen!</a>
              </div>

            </div>
          </div>
        </div>
        <div class="background_overlay"></div>


      </div>
    );
  }
}




const mapStateToProps = (state) => {
  return {
    clips: state.clipReducer.clips,
    advertisingclips: state.clipReducer.advertisingclips,
    settings: state.clipReducer.settings
  }
};


const mapDispatchToProps = (dispatch) => ({
  getClipsRequest: (payload) => dispatch(action.getClipsRequest(payload)),
  setSearchRequest: (payload) => dispatch(action.setSearchRequest(payload))

});

export default connect(mapStateToProps, mapDispatchToProps)(Footer)

