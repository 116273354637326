import React, {Component} from 'react';
//import {Link} from 'react-router-dom';
import {Container, Row, Col, CardGroup, Card, CardBody, Button, Input, InputGroup, InputGroupAddon, InputGroupText} from 'reactstrap';

import * as action from './action';
import * as action2 from '../../../redux/action'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
//import joobsterlogo from '../../../public/img/joobsterAllgemein64x64.png';
import Swal from 'sweetalert2'
import './background.css'
import Quoting from "./Quoting"

class Login extends Component {

  constructor(props) {
    super(props);
    this.state = {
     email: null,
     password: null,
   //  email: 'hello@joobster.at',
   //  password: 'Kitoke45',
      role: 'admin',
      emailpwreset: null, 
      
    }

    this.onEmailChange = this.onEmailChange.bind(this)
    this.onEmailChangePasswortReset = this.onEmailChangePasswortReset.bind(this)
    this.onPasswordChange = this.onPasswordChange.bind(this)

    this.handlePressKeyLoginEmail = this.handlePressKeyLoginEmail.bind(this)
    this.handlePressKeyPWReset = this.handlePressKeyPWReset.bind(this)
    this.signIn = this.signIn.bind(this)
    }

    componentDidMount(){
      var w = window,
      d = document,
      e = d.documentElement,
      g = d.getElementsByTagName('body')[0],
      x = w.innerWidth || e.clientWidth || g.clientWidth,
      y = w.innerHeight|| e.clientHeight|| g.clientHeight;
      
      this.setState({x:x,y:y});
    }


    onEmailChange(e) {
    this.setState({ email: e.target.value});

  }

  handlePressKeyLoginEmail(e){
    if (e.key === 'Enter') {
      this.signIn()
    }
  }



  onEmailChangePasswortReset(e) {
    this.setState({ emailpwreset: e.target.value});

  }

  handlePressKeyPWReset(e){
    if (e.key === 'Enter') {
      this.passwordReset()
    }
  }


  onPasswordChange(e) {
    this.setState({ password: e.target.value});

  }



   validateEmail(email)
  {

      if(/^[a-z0-9][a-z0-9-_\.]+@([a-z]|[a-z0-9]?[a-z0-9-]+[a-z0-9])\.[a-z0-9]{2,10}(?:\.[a-z]{2,10})?$/.test(email)) {
         return true;

      }else return false

  }


  passwordReset(){
    if (this.state.emailpwreset && this.validateEmail(this.state.emailpwreset)){
    this.props.onPressResetPassword({payload: { email: this.state.emailpwreset}})
    this.setState({ email: this.state.emailpwreset, emailpwreset : ''})

    }
    else

    Swal.fire({
      title: 'Error!',
      position: 'center',
      text: 'Emailadresse nicht korrekt / fehlt!',
      type: 'error',
      animation: true,
      customClass: {
        popup: 'animated tada'
      },
      showConfirmButton: false,
      timer: 1500
      //confirmButtonText: 'Cool'
    })

  }



  signIn(e)
  {
    console.log('username: ' + this.state.email + ' passwort ' + this.state.password)
    if (this.state.password && this.state.email) this.props.onPressSignin(this.state)
    else
    Swal.fire({
      title: 'Error!',
      position: 'center',
      text: 'Keinen Usernamen/Passwort angegeben!',
      type: 'error',
      animation: true,
      customClass: {
        popup: 'animated tada'
      },
      showConfirmButton: false,
      timer: 1500
      //confirmButtonText: 'Cool'
    })



  }

  render() {



    
    return (
      <div className="flex-row align-items-center" style={{marginTop: "15%"}}>
        <img className="bg" src={'https://source.unsplash.com/'+this.state.x/2+'x'+this.state.y/2+'/?natur'} />
        <Container>
          <Row className="justify-content-center">
            <Col md="8">
              <CardGroup>
                <Card className="p-4">
                  <CardBody>
                    <h1>Login</h1>
                    <p className="text-muted">Bei Ihrem Account anmelden</p>
                    <InputGroup className="mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="icon-user"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input type="text" value={this.state.email} onKeyPress={this.handlePressKeyLoginEmail} placeholder="Email" onChange={(e) => this.onEmailChange(e)} />
                    </InputGroup>
                    <InputGroup className="mb-4">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="icon-lock"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input type="password"  placeholder="Passwort" onKeyPress={this.handlePressKeyLoginEmail} onChange={(e) => this.onPasswordChange(e)} />
                    </InputGroup>
                    <Row>
                      <Col xs="6">
                        <Button color="primary" className="px-4" onClick={(e) => {this.signIn(e)}  }>Login</Button>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <Card className="text-white bg-warning py-4" style={{ width: 48 + '%' }}>


                  <CardBody className="">
                    <div>

                      <h1>Passwort</h1>
                      <p>Passwort Ihres Accounts vergessen?</p>
                      <InputGroup className="mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="icon-user"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input type="text" placeholder="Email" value={this.state.emailpwreset} onKeyPress={this.handlePressKeyPWReset} onChange={(e) => this.onEmailChangePasswortReset(e)} />
                    </InputGroup>
                    <Row>
                      <Col xs="6">
                    <Button color="primary" className="px-4" onClick={ () => {this.passwordReset()} } >Passwort zurücksetzen!</Button>
                  </Col>
                  </Row>
                    </div>
                  </CardBody>
                </Card>

              </CardGroup>
            </Col>
          </Row>
          <br/>
 <Row className="justify-content-center">
   <hr/>
 <Card>
                  <CardBody>
                   
                  <Quoting />
                  </CardBody>
                </Card>
 </Row>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authReducer: state.authReducer.auth
});

const mapDispatchToProps = (dispatch) => ({
    onPressSignin: (payload) => dispatch(action.authSigninRequest(payload)),
    getClipsRequest: (payload) => dispatch(action2.getClipsRequest(payload)), 
    onPressResetPassword: (payload) => dispatch(action.resetPasswordRequest(payload))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
