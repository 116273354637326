

import { Card, CardTitle, CardMedia } from 'material-ui';
import CircularProgress from '@material-ui/core/CircularProgress';
import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import _ from 'lodash';
import { Player, BigPlayButton, ControlBar, PosterImage, LoadingSpinner } from 'video-react';
import "../../../../node_modules/video-react/dist/video-react.css"; // import css
//import { makeStyles } from '@material-ui/core/styles';
import { CardActionArea } from '@material-ui/core';
//import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
//import { withStyles } from '@material-ui/core/styles';
import Image from 'material-ui-image'
//import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import FitText from '@kennethormandy/react-fittext';
import { EditorFormatAlignCenter } from 'material-ui/svg-icons';

import { withStyles } from "@material-ui/core/styles";
import { Jumbotron, Container, Tooltip, OverlayTrigger, Button } from 'react-bootstrap'
import DetailViewTabs from './DetailViewTabs'
import Example from './SingleVideoPopup';
import "video-react/dist/video-react.css"; // import css
import loadingCircle from '../images/loading.gif'
import settings from '../../../settings'
import Videotrimmer from './Videotrimmer'
import Chip from '@material-ui/core/Chip';
import i18n from "i18next";
import { withTranslation } from 'react-i18next';


const styles = {



  paper: {
    backgroundColor: 'rgba(0,0,0,.95)',


  },

  cardMedia: {
    maxHeight: 420,
    // width: 250, 
    // height: 0, 
    // overflow: 'hidden'


  },
  card: {
    cursor: 'pointer',
    height: '100%',
    //overflow: 'hidden'
  },
  bgImage: {
    width: 250,
  }
};

class MovieCardComponent extends React.Component {


  constructor(props) {
    super(props);
    // Track if the mouse hovering over the movie card
    this.state = {
      isMouseOver: false,
      movieid: null,
      showModal: false,
      moviedetails: null,
      sections: null,
      movies: null,
      keywordTimes: { start: 0, stop: 0 }
    };


    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }





  getConnectedMovies = async () => {
    const retval = await fetch(settings.api_base_path + '/group/' + this.props.movie.groupId);
    return retval.json();
  }


  // Prepare Sections "Advertising" - Section has to be excluded. This sections is dedicated to the slider! 
  async preparesections(topmovies) {
    var sections = [];

    if (topmovies) {
      topmovies.map(
        clip => {
          if (clip.sections != null && clip.sections != []) {
            clip.sections.map(cat => {
              if (sections.includes(cat) || cat === "Advertising") { }
              else
                sections.push(cat);
            })
          }

        }
      )
    }

    this.setState({ sections: sections });
    return sections;

  }

  async handleOpenModal() {

    console.log('clicked movie is: ', this.props.movie)
    // let groupclips = await this.getConnectedMovies();
    //  console.log('now clips are: ', groupclips.clips)
    // let sections =  await this.preparesections(groupclips.clips)
    // await this.setState({ sections: sections, movies: groupclips.clips})
    this.props.mode == "single" ? this.props.callback({ movieId: this.props.movie.id }) : this.setState({ showModal: true });
  }

  handleCloseModal() {
    this.setState({ showModal: false, keywordTimes: { start: 0, stop: 0 } });
  }


  setMovieIDNull() {
    this.setState({ movieid: null });
  }



  getNewColor = () => {
    function c() {
      var hex = Math.floor(Math.random()*256).toString(16);
      return ("0"+String(hex)).substr(-2); // pad with zero
    }
    return "#"+c()+c()+c();
  }



  render() {
    const { callback, movie, mode, classes, t } = this.props;
    // The CardTitle.subtitle won't render if it's null
    const subtitle = this.state.isMouseOver ? "by joobster" : null;
    



    return (
      <div>

        <Card style={styles.card}
          onMouseOver={() => this.setState({ isMouseOver: false })}
          onMouseLeave={() => this.setState({ isMouseOver: false })}
          onClick={() => {
            //this.setState({ keywordTimes: { start: 0, stop: movie.duration } })
            this.handleOpenModal()}
          }
        >
          <CardActionArea>
            <CardMedia style={styles.cardMedia}
              component="img"
              alt={movie.title}
              //  height="140"
              title={movie.title}
            >
              {
                this.state.isMouseOver ?
                  <Player
                    playsInline
                    src={this.props.movie.video.videoLow}
                    width="250"
                    autoPlay
                    onWaiting={() => <CircularProgress />}

                  >
                    <LoadingSpinner className="video-react-loading-spinner" />
                    <BigPlayButton className="big-play-button-hide" />
                    <PosterImage poster={loadingCircle} width="32" />
                    <ControlBar disableCompletely autoHide={false} />

                  </Player>
                  :
                  <Image
                    style={styles.bgImage}
                    onClick={   () => this.setState({ keywordTimes: { start: 0, stop: movie.duration } })}
                    src={movie.video.videoThumbLow}
                    aspectRatio={16 / 9}
                    animationDuration={5000}
                    loading={<CircularProgress disableShrink />}

                  />
              }
            </CardMedia>


            <CardContent >
              <Typography gutterBottom variant="h6" component="h3">

                <FitText compressor={0.5} minFontSize={8} maxFontSize={16} defaultFontSize={12} >
                  {movie.title}
                </FitText>

              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                {movie.subtitle}
              </Typography>

              {
                movie.hasOwnProperty('videoSearch') && movie.videoSearch && movie.videoSearch.length > 0 ?
                  movie.videoSearch.map(keywordSet =>
                    <span>
                    {
                    keywordSet.texts && keywordSet.texts.length > 0 && keywordSet.texts.map(
                      text => (

                        <Chip label={text}
                       // style={{backgroundColor: chipcolor}}
                          onClick={() => this.setState({ keywordTimes: { start: keywordSet.start, stop: keywordSet.stop } })}
                        />
                      
                      )
                    )
                  }
                  </span>                      

                  )

                  : null
              }

            </CardContent>

          </CardActionArea>

        </Card>


        <Dialog
          BackdropProps={{
            classes: {
              root: classes.paper
            }
          }}
          autoScrollBodyContent={true}
          modal={false}
          open={this.state.showModal}
          onRequestClose={this.handleCloseModal}
          onClose={this.handleCloseModal}
          maxWidth="md"
          fullWidth={true}
        >


          <DetailViewTabs mode={"single"} callback={this.props.callback} sections={this.state.sections} movies={this.state.movies} component1={
            <div>



                  <Videotrimmer
                    // valueCallback={(timeborders) => {this.props.valueCallback(elem.id, timeborders); } }
                    step={0.01} min={0} max={movie.duration}
                    value={[this.state.keywordTimes.start, this.state.keywordTimes.stop]}
                    id={movie.id}
                    video={movie.video.videoLow}
                    poster={movie.video.hasOwnProperty('image') ? movie.video.image : movie.video.videoThumbLow}
                    isImage={movie.video.hasOwnProperty('image') && (['jpg', 'png', 'jpeg']).includes(movie.video.image.split('.').pop().toLowerCase())}
                  />

              
<br/>
{
                movie.hasOwnProperty('videoSearch') && movie.videoSearch && movie.videoSearch.length > 0 ?
                  movie.videoSearch.map(keywordSet =>
                    <span>
                        <p><strong>{t('moviecard.timerange')}: {t('general.second')} {keywordSet.start} - {t('general.second')} {keywordSet.stop}</strong><br/>

                        {
                          
                    keywordSet.texts && keywordSet.texts.length > 0 && keywordSet.texts.map(
                      text => (

                        <Chip 
                       // style={{backgroundColor: chipcolor}}
                        
                        label={text}
                          onClick={() => {
                            console.log('now clicked: ', text)
                            this.setState({ keywordTimes: { start: keywordSet.start, stop: keywordSet.stop } })}
                          }
                        />
                      )
                    )
                        }
                        </p> 
                    </span>
                    

                  )

                                         

                  

                  : null
              }  
              <br/>            
            </div>
          }
          />



        </Dialog>



      </div>
    );

  }
}


export default withTranslation()(withStyles(styles)(MovieCardComponent));
