import React from "react";
import Carousel from "react-multi-carousel";
import "./WithScrollbar.css";
import { Player, BigPlayButton } from 'video-react';
import {Card, CardBody, CardHeader} from "reactstrap"
import { connect } from 'react-redux';
import * as action from '../../../redux/action'



const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};


class CarouselStartPage extends React.Component{

  constructor(props) {
    super(props);
    this.state = { additionalTransfrom: 0 };
  }

  
  render() {
    const CustomSlider = ({ carouselState }) => {
      let value = 0;
      let carouselItemWidth = 0;
      if (this.Carousel) {
        carouselItemWidth = this.Carousel.state.itemWidth;
        const maxTranslateX = Math.round(
          // so that we don't over-slide
          carouselItemWidth *
            (this.Carousel.state.totalItems -
              this.Carousel.state.slidesToShow) +
            150
        );
        value = maxTranslateX / 100; // calculate the unit of transform for the slider
      }
      const { transform } = carouselState;
      return (
        <div className="custom-slider">
          <input
            type="range"
            value={Math.round(Math.abs(transform) / value)}
            defaultValue={0}
            max={
              (carouselItemWidth *
                (carouselState.totalItems - carouselState.slidesToShow) +
                (this.state.additionalTransfrom === 150 ? 0 : 150)) /
              value
            }
            onChange={e => {
              if (this.Carousel.isAnimationAllowed) {
                this.Carousel.isAnimationAllowed = false;
              }
              const nextTransform = e.target.value * value;
              const nextSlide = Math.round(nextTransform / carouselItemWidth);
              if (
                e.target.value == 0 &&
                this.state.additionalTransfrom === 150
              ) {
                this.Carousel.isAnimationAllowed = true;
                this.setState({ additionalTransfrom: 0 });
              }
              this.Carousel.setState({
                transform: -nextTransform, // padding 20px and 5 items.
                currentSlide: nextSlide
              });
            }}
            className="custom-slider__input"
          />
        </div>
      );
    };
    return (
      <Carousel
      ssr={false}
      ref={el => (this.Carousel = el)}
      centerMode={true}
      customButtonGroup={<CustomSlider />}
      itemClass="slider-image-item"
      responsive={responsive}
      containerClass="carousel-container-with-scrollbar"
      additionalTransfrom={-this.state.additionalTransfrom}
      arrows
      centerMode={false}
      className=""
      dotListClass=""
      draggable
      focusOnSelect={false}
      infinite
      keyBoardControl
      minimumTouchDrag={80}
      partialVisbile="right"
      renderButtonGroupOutside={false}
      renderDotsOutside={false}      
        beforeChange={nextSlide => {
          if (nextSlide !== 0 && this.state.additionalTransfrom !== 150) {
            this.setState({ additionalTransfrom: 150 });
          }
          if (nextSlide === 0 && this.state.additionalTransfrom === 150) {
            this.setState({ additionalTransfrom: 0 });
          }
        }}
      >


       
        

        {
          this.props.advertisingclips && this.props.advertisingclips[0]!=null && this.props.advertisingclips.map( singlemovie => 
            
        <div class="image-container increase-size">
          <Card>
            <CardHeader style={{backgroundColor: '#363B40', color: '#ffffff'}}>
              {singlemovie.title} | {singlemovie.subtitle}
            </CardHeader>
            <CardBody>
          <Player 

          playsInline 
          poster={singlemovie.video.videoThumbLow} 
          src={singlemovie.video.video} >
            <BigPlayButton position="center" />
            </Player>
          </CardBody>
          </Card>
        </div>
        




          )
        }

      </Carousel>
    );
  
}  


}



const mapStateToProps = (state) => {
  return {
    clips: state.clipReducer.clips,
    advertisingclips: state.clipReducer.advertisingclips, 
  }
};


const mapDispatchToProps = (dispatch) => ({
    getClipsRequest: (payload) => dispatch(action.getClipsRequest(payload))
});

export default  connect(mapStateToProps, mapDispatchToProps)(CarouselStartPage)

