import React from 'react';
import range from 'lodash/range';
import styled from 'styled-components';
import ItemsCarousel from 'react-items-carousel';
import joobanner from './webhtml/images/Videobanner_joobster_gemeinderegional.jpg';



const noOfItems = 5;
const noOfCards = 1;
const autoPlayDelay = 4500;
const chevronWidth = 60;

const Wrapper = styled.div`
  padding: 0 ${chevronWidth}px;
  max-width: 700;
  margin: 0 auto;
`;

const SlideItem = styled.div`
  height: 120px;
  background: #EEE;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: bold;
`;


const carouselItems = range(noOfItems).map(index => (
  <SlideItem key={index}>
    {index+1}
  </SlideItem>
));





export default class AdvertisingSlider extends React.Component {
  state = {
    activeItemIndex: 0,
  };

  componentDidMount() {
    this.interval = setInterval(this.tick, autoPlayDelay);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  tick = () => this.setState(prevState => ({
    activeItemIndex: (prevState.activeItemIndex + 1) % (noOfItems-noOfCards + 1),
  }));

  onChange = value => this.setState({ activeItemIndex: value });

  render() {

    
    return (
    <div style={
      {
        float: 'right',
        width: 728,
        height: 110,
        paddingTop: 0,
        marginTop: 0,
      }
    } >
  <ItemsCarousel
          gutter={12}
          numberOfCards={noOfCards}
          activeItemIndex={this.state.activeItemIndex}
          requestToChangeActive={this.onChange}
          chevronWidth={chevronWidth}
          outsideChevron={false}
  >

    {Array.from(new Array(10)).map((_, i) =>
      <img
        key={i}
        style={{
          height: 120,
        }}
        src={joobanner}
      />
    )}
  </ItemsCarousel>
  </div>

    );
  }
}
