import React, {useState, useEffect} from 'react';

import { BrowserRouter, Route, Redirect , Link, Switch } from 'react-router-dom';

import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import App from '../../App'
import Login from '../components/Login/Login'
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import Button from 'react-bootstrap/Button'
import { connect } from 'react-redux';
import * as action from '../../redux/action'
import {
  fade,
  ThemeProvider,
  withStyles,
  makeStyles,
  createMuiTheme,
} from '@material-ui/core/styles';
//import 'bootstrap/dist/css/bootstrap.min.css';
import settings from '../../settings'



const BootstrapInput = withStyles(theme => ({
  root: {
   /* 'label + &': {
      marginTop: theme.spacing(2),
    },*/
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    border: '1px solid #ced4da',
    fontSize: 16,
    width: 'auto',
    padding: '10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}))(InputBase);


class Navigation extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
        searchstring: ""
    }
  //  this.doSomeSearchOnClips = this.doSomeSearchOnClips.bind(this)
    this.setSearchText = this.setSearchText.bind(this)
    //this.handleKeyPress = this.handleKeyPress.bind(this)
    this.onEnter = this.onEnter.bind(this)
  }

    
    componentDidMount(){
  

    if (this.props.auth.sessionId) this.props.getClipsRequest( {authToken: this.props.auth.sessionId})

  }

    setSearchText( e) {
      this.setState({
        searchstring: e.target.value
      })
    }

    /*
    doSomeSearchOnClips(target){
      var searchclips = this.props.clips

      this.state.searchstring != "" ? 
      this.props.setSearchRequest({clips: searchclips.filter( f => f.title.toUpperCase().includes( this.state.searchstring.toUpperCase()) || (f.tags.filter( tag => tag.toUpperCase().includes(this.state.searchstring.toUpperCase())).length > 0   ) )})
      : 
      this.props.getClipsRequest()
    }
    */
/*
    handleKeyPress(target){
      if(target.charCode==13 ){
        var searchclips = this.props.clips

        target.target.value != "" ? 
        this.props.setSearchRequest({clips: searchclips.filter( f => f.title.toUpperCase().includes( target.target.value.toUpperCase()) || (f.tags.filter( tag => tag.toUpperCase().includes(target.target.value.toUpperCase())).length > 0    ))})
        : 
        this.props.getClipsRequest()
      
    }
  }
  */




  getMovieData = async (searchstring) => {
    let domain = settings.mode === "dev" ? "portal.joobster.at" : window.location.hostname

  let path = settings.api_base_path + settings.portalpath +'/clip?domain='+domain+'&query='+searchstring; 
  if (this.props.choosensections) path = settings.api_base_path + settings.portalpath+ '/clip?domain='+domain+'&query='+searchstring+'&section='+this.props.choosensections

  var retval = await fetch( path, 
    {
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',
          'Authorization' : 'Bearer ' + this.props.auth.sessionId
      }
  }     
    );
  
    console.log(retval)

    return  retval.json();
}

  async onEnter(e){
    if(e.charCode==13 ){
    console.log('Turnbackvalue of Searchstring is: ' + e + " ", await this.getMovieData(this.state.searchstring))

    this.props.setSearchRequest(await this.getMovieData(this.state.searchstring));
    }

  }

  async onSearchClick(e){
    console.log('Turnbackvalue of Searchstring is: ' + e + " ", await this.getMovieData(this.state.searchstring))

    this.props.setSearchRequest(await this.getMovieData(this.state.searchstring));
    

  }


  render( ){

    console.log('Auth: ', this.props.auth)
    console.log('Settings: ', this.props.settings)

    return (
  <div>


    
    <div className="menu_container clearfix style_7">

<Navbar bg="light" expand="lg" >
  <Navbar.Brand href="/home"><img width="160px" src={this.props.settings.logo} /></Navbar.Brand>
  <Navbar.Toggle aria-controls="basic-navbar-nav" />
  <Navbar.Collapse id="basic-navbar-nav">

    <Nav className="mr-auto">
    {
    /*
    <Link className="nav-link" to="/home">Home</Link>

    <Link className="nav-link" to="/contact">Kontakt</Link>
  */
}
    </Nav>
  


    <BootstrapInput onKeyPress={this.onEnter} variant="outlined"  onChange={(e) => this.setSearchText(e)} />
    
    <div className="input-group-prepend">
       
       <Button style={{ backgroundColor: this.props.settings.secondaryColor, border: 0}}><SearchIcon  onClick={(e) => this.onSearchClick(e)}/></Button>
      
     </div>
  </Navbar.Collapse>
</Navbar>
</div>
{
  /*


<Switch>
          <Route path="/" exact component={App} />
          <Route path="/home" component={App} />
          <Route path="/login" component={Login} />

        </Switch>
  */
}
  </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    clips: state.clipReducer.clips,
    auth: state.authReducer.auth, 
    settings: state.clipReducer.settings, 
  }
};


const mapDispatchToProps = (dispatch) => ({
    getClipsRequest: (payload) => dispatch(action.getClipsRequest(payload)), 
    setSearchRequest: (payload) => dispatch(action.setSearchRequest(payload))

});

export default  connect(mapStateToProps, mapDispatchToProps)(Navigation)
