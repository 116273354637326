import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PhoneIcon from '@material-ui/icons/Phone';
import FavoriteIcon from '@material-ui/icons/CategoryRounded';
import PersonPinIcon from '@material-ui/icons/PersonPin';
import HelpIcon from '@material-ui/icons/Help';
import ShoppingBasket from '@material-ui/icons/ShoppingBasket';
import ThumbDown from '@material-ui/icons/ThumbDown';
import ThumbUp from '@material-ui/icons/ThumbUp';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import { Container, Row } from 'react-bootstrap';
import MovieList from '../movie-list/movie-list.component';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
   // backgroundColor: theme.palette.background.paper,
  },
}));

export default class DetailViewTabs extends React.Component {
  
   
  // [value, setValue] = React.useState(0);
  
   constructor(props){
   super(props); 
   this.state = {
     value: 0
   }
  }


    handleChange = (event, newValue) => {
    //setValue(newValue);
    this.setState({
      value: newValue
    })
  };

   getMoviesForSection(section){

    let movies = this.props.movies;
    movies =  movies.filter( movie =>  movie.sections.includes(section))
    console.log('Filterd movies for section ' + section + ' are: ', movies)
    return  movies
  }


  render() {
    const classes = useStyles;
  return (
    <div className={classes.root}>
      <AppBar position="static" color="white">
        <Tabs
          value={this.state.value}
          onChange={this.handleChange}
          variant="scrollable"
          scrollButtons="on"
          indicatorColor="primary"
          textColor="primary"
          aria-label="scrollable force tabs example"
        >
          {/*
            <Tab label="Kurzvorstellung" icon={<PhoneIcon />} {...a11yProps(0)} />
          <Tab label="Wir suchen (Jobs)" icon={<FavoriteIcon />} {...a11yProps(1)} />
          <Tab label="Das sagen unsere Mitarbeiter" icon={<PersonPinIcon />} {...a11yProps(2)} />
          */}
       
       { this.props.mode == "single" && this.props.tab != "clip" ?
       <Tab label={ "Ausgewählter Clip" } icon={<FavoriteIcon />} {...a11yProps(0)} />
       : null }

       {
         /*
          this.props.sections.map( (cat,index) => {
            return  <Tab label={ cat } icon={<FavoriteIcon />} {...a11yProps(index + (this.props.mode == "single" && this.props.tab != "clip" ? 1 : 0))} />
          })
          */
        }
       
          
        
        </Tabs>
      </AppBar>

      { this.props.mode == "single" && this.props.tab != "clip" ?
      <TabPanel value={this.state.value} index={0}>
              {this.props.component1}
      </TabPanel> : null }
      {
        /*
        this.props.sections.map((section, index) => {


          
          {
            return <TabPanel value={this.state.value} index={index + (this.props.mode == "single" && this.props.tab != "clip" ? 1 : 0)} >
              <Container>
                <Row>

                  <MovieList mode={this.props.mode} callback={this.props.callback} movies={this.getMoviesForSection(section)} />


                </Row>
              </Container>
            </TabPanel>

          }

        })
*/        
      }


    </div>
  );
  }
}
