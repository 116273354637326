import React, { Component, Fragment } from 'react';
import SelectSearch from 'react-select-search';
import './style.css';
import {Row, Col, Container, Button} from 'react-bootstrap';
import { connect } from 'react-redux';
import * as action from '../../redux/action'
import Badge from '@material-ui/core/Badge';
import SearchIcon from '@material-ui/icons/Search';
import ResetIcon from '@material-ui/icons/Clear';
import { withStyles } from '@material-ui/styles';

import SearchField from "react-search-field";
import { Form, Input } from 'reactstrap';
import settings from '../../settings'

const styles = theme => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
    },

    center: {
      margin: '0 auto', 
      width: '400px'
    }, 
    textstyle: {
      border: '1px #000 solid',
      display: 'inline-flex',
      justifyContent: 'space-between',
    }, 
    customBadge: {
      backgroundColor: props => props.badgecolor,
      color: "white"
    }    
});






class SearchButton extends React.Component {


  constructor(props) {
    super(props);
    this.state = {
      dropdownOpen: false,
      choosenTitle: '',
      parentId: null,
      supergroups: null, 
      searchText: ""
    }

    //this.toggle = this.toggle.bind(this);
    //this.sgClicked = this.sgClicked.bind(this);
    //this.getChangedValueOfParent = this.getChangedValueOfParent.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onEnter = this.onEnter.bind(this);
    this.resetOnEnter = this.resetOnEnter.bind(this);

  }


  async onChange(e){
    if (e.key === 'Enter') {
      console.log('ENTER KEY PRESSED')
      this.props.setSearchRequest(await this.getMovieData(this.state.searchText));

    }else 
    {
      this.setState({ searchText: e.target.value});
    }

  }

  async onEnter(e){

    let moviedata = await this.getMovieData(this.state.searchText); 
    console.log('Moviedata: ', moviedata)
    await this.setState({ searchText: ""});

    this.props.setSearchRequest(moviedata);

  }
  async resetOnEnter(e){

    await this.setState({ searchText: ""});
    this.onEnter()
   


  }
  


  getMovieData = async (searchstring) => {

    let domain = settings.mode === "dev" ? "portal.joobster.at" : window.location.hostname

    let path = settings.api_base_path + settings.portalpath + '/clip?domain='+domain+'&query='+searchstring; 
    if (this.props.choosensections) path = settings.api_base_path + settings.portalpath+ '/clip?domain='+domain+'&query='+searchstring+'&section='+this.props.choosensections

    var retval = await fetch( path, 
      {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer ' + this.props.auth.sessionId
        }
    }     
      );
    
      console.log(retval)

      return  retval.json();
  }

  render() {
    var { classes } = this.props
    const localthis = this;
    const props = this.props;




    return (
      <Fragment>
                <div  >
                  
                  <Badge 
                  classes={{ badge: classes.customBadge }}
                  style={{ 'marginBottom': -85, 'marginLeft': -60}} badgeContent={this.props.badgeContent} >
      <SearchIcon />
    </Badge>




</div>        
    <div className="input-group">

      <input
          value={this.state.searchText}
          className="form-control form-control-lg"
      //  style={{ background: '#ffffff', border: '1px #000 solid', 
          //margin: '0 auto', 
       //   width: "200%", 
        //  alignItems: 'center', 
        // }}
  onChange={this.onChange}
  onKeyDown={this.onChange}
  //onEnter={this.onEnter}

  // searchText="This is initial search text"
  />
      <div className="input-group-prepend">
       
        <Button  style={{ backgroundColor: this.props.settings.secondaryColor, border: 0}} ><SearchIcon  onClick={this.onEnter}/></Button>
        
        <Button  style={{ backgroundColor: "red", border: 0}} ><ResetIcon style={{ backgroundColor: "red"}}  onClick={this.resetOnEnter}/></Button>

      </div>
  
      

       
           
           </div>

           </Fragment>
       

    );
  }

}

const mapStateToProps = (state) => {
  return {
    clips: state.clipReducer.clips,
    auth: state.authReducer.auth, 
    settings: state.clipReducer.settings, 
  }
};


const mapDispatchToProps = (dispatch) => ({
    getClipsRequest: (payload) => dispatch(action.getClipsRequest(payload)), 
    setSearchRequest: (payload) => dispatch(action.setSearchRequest(payload))

});

export default  withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(SearchButton))
