import {fork, actionChannel, put, take, call} from 'redux-saga/effects';

import Swal from 'sweetalert2'
import * as loginActions from './action';
import settings from '../../../settings'

/**
 * Saga watchers
 */
export function *watchSignin() {
    let payload;
    const reqChannel = yield actionChannel(loginActions.AUTH_SIGNIN_REQUEST)
    while (payload = yield take(reqChannel)) {
        yield fork(signin, payload);
    }
}

export function *watchPWDReset() {
    let payload;
    const reqChannel = yield actionChannel(loginActions.PWD_RESET_REQUEST)
    while (payload = yield take(reqChannel)) {
        yield fork(pwdreset, payload);
    }
}

/**
 * Auth Signin endpoint
 * @param {*} payload
 */
const httpSignin = (payload) => {

    let header = new Headers({
      //  'Access-Control-Allow-Origin':'*',
      //  mode: 'cors',
        'Content-Type': 'application/json'
});


    return fetch( settings.api_base_path + settings.portalpath + '/signin',
    {
        method: 'POST',
        headers: header,
        body: JSON.stringify(payload)
    })
    .then((response) => response.json())
    .then((response) => response);
};

function *signin(action) {
    try {

        const result = yield call(httpSignin, action.payload)



        if (result.status == 'ok') {
            yield put({ type: loginActions.AUTH_SUCCESS, payload: result.auth });

        } else {
            Swal.fire({
                title: 'Error!',
                position: 'center',
                text: 'Kein Zugriff gewährt / Username oder Passwort falsch! ',
                animation: true,
                customClass: {
                  popup: 'animated tada'
                },
                type: 'error',
                showConfirmButton: false,
                timer: 1500
                //confirmButtonText: 'Cool'
              })

            yield put({ type: loginActions.AUTH_ERROR, payload: {}, error: true });
        }

    } catch(error) {
        Swal.fire({
            title: 'Error!',
            position: 'center',
            animation: true,
            customClass: {
              popup: 'animated tada'
            },
            text: 'Kein Zugriff gewährt / Username oder Passwort falsch! ',
            type: 'error',
            showConfirmButton: false,
            timer: 1500
            //confirmButtonText: 'Cool'
          })
        yield put({ type: loginActions.AUTH_ERROR, payload: error, error: true  });
    }
}


const pwdreset_fetch = (payload) => {

    const queryparams = {
        'email': payload.payload.email
    }
    return fetch( settings.api_base_path + '/resetpassword/de',
    {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(queryparams)
    })
    .then((response) => response.json())
    .then((response) => {
        console.log(' pwd reset is: ', response);
        response.status == "error" ?
        Swal.fire({
            title: 'Error!',
            position: 'top-end',
            animation: true,
            customClass: {
              popup: 'animated tada'
            },
            text: 'Fehler bei Passwort-Zurücksetzen-Anfrage ',
            type: 'error',
            showConfirmButton: false,
            timer: 1500
            //confirmButtonText: 'Cool'
          }) :
          Swal.fire({
            title: 'Erfolgreich!',
            position: 'top-end',
            animation: true,
            customClass: {
              popup: 'animated tada'
            },
            text: 'Überprüfen Sie Ihr Email-Postfach!',
            type: 'success',
            showConfirmButton: false,
            timer: 1500
            //confirmButtonText: 'Cool'
          })

    });
};

function *pwdreset(action) {
    try {
        const result = yield call(pwdreset_fetch, action.payload)


        if (result.status == 'ok') {
            yield put({ type: loginActions.PWD_SUCCESS, payload: result });

        } else {
            yield put({ type: loginActions.PWD_ERROR, payload: {}, error: true });
        }

    } catch(error) {
        yield put({ type: loginActions.PWD_ERROR, payload: error, error: true  });
    }
}
