/**
 * Clip action types
 */
export const GET_CLIPS_REQUEST = 'GET_CLIPS_REQUEST';
export const GET_CLIPS_SUCCESS = 'GET_CLIPS_SUCCESS';
export const SET_CLIPS_SEARCHREQUEST = 'SET_CLIPS_SEARCHREQUEST'
export const CLIP_SYNC_REQUEST = 'CLIP_SYNC_REQUEST';
export const CLIP_SYNC_RESPONSE = 'CLIP_SYNC_RESPONSE';

export const CLIPS_ERROR = 'CLIPS_ERROR';
export const UPDATECLIP_LOCALSTORE = 'CLIPS_UPDATELOCAL';


export const getClipsRequest = (payload) => ({
    type: GET_CLIPS_REQUEST,
    payload
});

export const setSearchRequest = (payload) => ({
  type: SET_CLIPS_SEARCHREQUEST,
  payload
});

export const updateClipLocalStore = (payload) => ({
    type: UPDATECLIP_LOCALSTORE,
    payload
});



export const getClipsSuccess = (payload) => ({
    type: GET_CLIPS_SUCCESS,
    payload
});

export const clipSyncRequest = (payload) => ({
    type: CLIP_SYNC_REQUEST,
    payload
});

export const clipSyncResponse = (payload) => ({
    type: CLIP_SYNC_RESPONSE,
    payload
});

export const clipsError = (error) => ({
    type: CLIPS_ERROR,
    error
});

