import {Button, Overlay} from 'react-bootstrap'
import React, {useState,useRef} from 'react'
import { Player, BigPlayButton, ControlBar } from 'video-react';
import PopoverStickOnHover from './PopoverStickonHover'

const Example = props =>  {
  const [show, setShow] = useState(false);
  const [profileState, setProfileState] = useState(props);


  const target = useRef(null);


  return (
    <div >


        <Player
                poster={profileState.movie.video.videoThumbLow} src={profileState.movie.video.video}
                autoPlay
                >
                <ControlBar disableCompletely disableDefaultControls={true}></ControlBar>
    </Player>
  


    </div>
  );
}

export default Example;
