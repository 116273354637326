import React from 'react';
import ReactDOM from 'react-dom';
//import './index.css';
import './modules/components/webhtml/style/style.css'
import './modules/components/webhtml/style/menu_styles.css'
import './modules/components/webhtml/style/reset.css';
import './modules/components/webhtml/style/superfish.css';
import './modules/components/webhtml/style/prettyPhoto.css';
import './modules/components/webhtml/style/jquery.qtip.css';
import './modules/components/webhtml/style/animations.css';
import './modules/components/webhtml/style/responsive.css';
import './modules/components/webhtml/style/odometer-theme-default.css';
import Navigation from './modules/components/Navigation'
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App'

import store, {persistor}  from './store';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import * as serviceWorker from './serviceWorker';

/*
<link
  rel="stylesheet"
  href="https://maxcdn.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css"
  integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T"
  crossorigin="anonymous"
/>
*/


function render()
{ReactDOM.render(
  (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
         <App />
      </PersistGate>
    </Provider>
  

  ),
  document.getElementById('root'));
}
serviceWorker.unregister();
render();
store.subscribe(render);
