import React, { Component } from 'react'


const quotes = [
    {
        "quote": "Die Entfernung ist unwichtig. Nur der erste Schritt ist wichtig.",
        "author": "Marquise du Deffand"
    },
    {
        "quote": "Wenn du nichts veränderst, wird sich auch nichts verändern!",
        "author": "Sparky Anderson"
    },

    {
        "quote": "Was immer du tun kannst oder träumst es zu können, fang damit an.",
        "author": "Johann Wolfgang von Goethe"
    },
    {
        "quote": "Mut steht am Anfang des Handelns, Glück am Ende.",
        "author": "Demokrit"
    },
    {
        "quote": "Nur wer selbst brennt, kann Feuer in anderen entfachen.",
        "author": "Augustinus"
    },
    {
        "quote": "Wer etwas will, findet Wege. Wer etwas nicht will, der findet Gründe.",
        "author": "Götz Werner"

    }, 
    {
        "quote": "Motivation bringt Dich in Gang. Gewohnheit hält Dich in Schwung.",
        "author": "Jim Rohn"

    } , 
    {
        "quote": "Wer kämpft, kann verlieren. Wer nicht kämpft, hat schon verloren!",
        "author": "Bertolt Brecht"

    }, 
    {
        "quote": "Wer aufhört, besser werden zu wollen, hört auf, gut zu sein.",
        "author": "Marie von Ebner-Eschenbach"

    }, 
    {
        "quote": "Die Kunst ist, einmal mehr aufzustehen, als man umgeworfen wird.",
        "author": "Winston Churchill"

    }, 
    {
        "quote": "Wer einen Misserfolg nur als kleinen Umweg betrachtet, verliert nie sein Ziel aus den Augen.",
        "author": "Martin Luther"

    }, 
    {
        "quote": "Unsere Körper sind unsere Gärten – unsere Willen sind unsere Gärtner.",
        "author": "William Shakespeare"

    }, 
    {
        "quote": "Das Geheimnis des Erfolgs ist anzufangen.",
        "author": "Mark Twain"

    }, 
    {
        "quote": "Erfolg hat drei Buchstaben: TUN",
        "author": "Johann Wolfgang von Goethe"

    }, 
    {
        "quote": "Unsere Fehlschläge sind oft erfolgreicher als unsere Erfolge.",
        "author": "Henry Ford"

    }, 
    {
        "quote": "Es ist nie zu spät, das zu werden, was man hätte sein können.",
        "author": "George Eliot"

    }, 
    {
        "quote": "Alle Träume können wahr werden, wenn wir den Mut haben, ihnen zu folgen.",
        "author" : "Walt Disney"
    }, 
    {
        "quote": "Glaube, dass du kannst und du bist schon halb dort.",
        "author" : "Greg Plitt"
    }, 
    {
        "quote": "Ziele sind Träume mit einer Deadline.",
        "author" : "Napoleon Hill"
    }, 
    {
        "quote": "Wer immer tut, was er schon kann, bleibt immer das, was er schon ist.",
        "author" : "Henry Ford"
    }, 
    {
        "quote": "Nur wer sein Ziel kennt, findet den Weg.",
        "author" : "Laozi"
    }, 
    {
        "quote": "Man muss das Unmögliche versuchen, um das Mögliche zu erreichen.",
        "author" : "Hermann Hesse"
    }, 
    {
        "quote": "Wer nicht mutig genug ist, Risiken einzugehen, wird es im Leben zu nichts bringen.",
        "author" : "Muhammed Ali"
    }, 
    {
        "quote": "Jeder Mensch ist der Architekt seiner eigenen Zukunft.",
        "author" : "Sallust"
    }, 
    {
        "quote": "Sorge dich gut um deinen Körper. Es ist der einzige Ort, den du zum Leben hast.",
        "author" : "Jim Rohn"
    }, 
    {
        "quote": "Wenn du die Absicht hast, dich zu erneuern, tu es jeden Tag.",
        "author" : "Konfuzius"
    }, 
    {
        "quote": "Unser Schicksal hängt nicht von den Sternen ab, sondern von unserem Handeln.",
        "author" : "William Shakespeare"
    }, 
    {
        "quote": "Erfahrung nennt man die Summe aller Irrtümer.",
        "author" : "Thomas Eidson"
    }, 
    {
        "quote": "Die Natur hält etwas vom Einfachen. Ein Mehr ist vergebens, wenn ein Weniger genügt.",
        "author" : "Isaac Newton"
    }, 
    {
        "quote": "Alles sollte so einfach wie möglich gemacht werden, aber nicht einfacher.",
        "author" : "Albert Einstein"
    }, 
    {
        "quote": "Was du heute denkst, wirst du morgen tun.",
        "author" : "Leo Tolstoi"
    }, 
    {
        "quote": "Die Definition von Wahnsinn ist, immer wieder das Gleiche zu tun und andere Ergebnisse zu erwarten.",
        "author" : "Albert Einstein"
    }                                      
]

export default class Quoting extends Component {

  //state
  state = {
    quote: quotes[0].quote,
    author: quotes[0].author
  }

  componentDidMount(){
      this.generateRandomQuote(quotes)
  }

  //generate diffrent quote function
  generateRandomQuote = (arr) => {
    //get random numbers
    let num = Math.floor(Math.random() * quotes.length)

    let newQuote = quotes[num];

    //update state
    this.setState({
      quote: newQuote.quote,
      author: newQuote.author
    })

    this.shuffleQuotes(quotes)

  }

  //shuufle quotes function
  shuffleQuotes = (arr) => {
    return arr.sort(function () { return 0.5 - Math.random() });
  }

  render() {
    return (
      <div>
          <h3>{this.state.quote}</h3>
          <p>by {this.state.author}</p>
        
      </div>
    )
  }
}