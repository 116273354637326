import { combineReducers } from 'redux';
import clipReducer from '../redux/reducer';
import authReducer from '../modules/components/Login/reducer';


//ConnectedRouter(createBrowserHistory)

export default combineReducers({
    clipReducer,
    authReducer, 
})


