import React, { Component, Fragment } from 'react';
import MovieBrowser from './modules/movie-browser/movie-browser.container';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import { connect } from 'react-redux';
import * as action from './redux/action'
import { BrowserRouter, Route, Redirect , Link, Switch } from 'react-router-dom';
import Navigation from './modules/components/Navigation'; 
import Login from './modules/components/Login/Login'
import Footer from './modules/components/webhtml/Footer'
import MovieBrowserContainer from './modules/movie-browser/movie-browser.container'
import './i18n'

class App extends Component {


  

  async componentDidMount() {
    
    await this.props.getClipsRequest({authToken: this.props.auth.sessionId})
  
  }


  render() {



    return (
      <BrowserRouter>



        <div>
          <Route
            render={() =>
              <div>


                {
                    (this.props.settings.hasOwnProperty('authRequired') && this.props.settings.authRequired !== true ) ?
                  <div>
                    <MuiThemeProvider>
                      <Navigation />
                      <MovieBrowser />
                    </MuiThemeProvider>
                    

                     <Footer />
                     </div>
                    : this.props.auth && this.props.auth.hasOwnProperty('sessionId') &&  this.props.auth.sessionId ?  
                      <div>
                      <MuiThemeProvider>
                        <Navigation />
                        <MovieBrowser />
                      </MuiThemeProvider>
                      

                      <Footer />
                      </div>                    
                    : <Login />
                }


              </div>
            }
          />
        </div>



      </BrowserRouter>

    );
  }
}

const mapStateToProps = (state) => {
  return {
    clips: state.clipReducer.clips,
    auth: state.authReducer.auth, 
    status: state.clipReducer.status,
    settings: state.clipReducer.settings
  }
};


const mapDispatchToProps = (dispatch) => ({
    getClipsRequest: (payload) => dispatch(action.getClipsRequest(payload)),
    setSearchRequest: (payload) => dispatch(action.setSearchRequest(payload))

});

export default  connect(mapStateToProps, mapDispatchToProps)(App)



