

import { Card, CardTitle, CardMedia } from 'material-ui';
import CircularProgress from '@material-ui/core/CircularProgress';
import React from 'react';
import { Dialog } from 'material-ui';
import _ from 'lodash';
import { Player } from 'video-react';
import "../../../../node_modules/video-react/dist/video-react.css"; // import css
//import { makeStyles } from '@material-ui/core/styles';
import { CardActionArea } from '@material-ui/core';
//import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
//import { withStyles } from '@material-ui/core/styles';
import Image from 'material-ui-image'
//import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import FitText from '@kennethormandy/react-fittext';





class MovieLinkComponent extends React.Component {


  constructor(props) {
    super(props);
    // Track if the mouse hovering over the movie card
    this.state = {
      isMouseOver: false,
      movieid: null,
      showModal: false,
      moviedetails: null
    };


    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }


  handleOpenModal() {
    this.setState({ showModal: true });
  }

  handleCloseModal() {
    this.setState({ showModal: false });
  }


  setMovieIDNull() {
    this.setState({ movieid: null });
  }

  render() {
    const { movie } = this.props;
    const classes = this.props.useStyles;
    // The CardTitle.subtitle won't render if it's null
    const subtitle = this.state.isMouseOver ? "by joobster" : null;


    return (
      <div>

        <p onMouseOver={() => this.setState({ isMouseOver: true })}
          onMouseLeave={() => this.setState({ isMouseOver: false })}
          onClick={() => this.handleOpenModal()} >
          {this.props.movie.title}
        </p>

        <Dialog 
          autoScrollBodyContent={true}
          modal={false}
          open={this.state.showModal}
          onRequestClose={this.handleCloseModal}
        >
          <FitText compressor={0.5} minFontSize={12} maxFontSize={48} defaultFontSize={12} >  <h2> {this.props.movie.title} {this.props.movie.subtitle ? " | " + this.props.movie.subtitle : ""} </h2> </FitText>
          <Player playsInline poster={this.props.movie.video.videoThumbLow} src={this.props.movie.video.video} />

        </Dialog>

      </div>
    );

  }
}


export default MovieLinkComponent;
