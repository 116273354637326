import { fork } from 'redux-saga/effects';

// import all sagas
import { watchGetClips } from '../redux/saga';
import { watchSignin, watchPWDReset } from '../modules/components/Login/saga';




export default function* rootSaga() {
 yield [
    fork(watchGetClips),
    fork(watchSignin), 
    fork(watchPWDReset)
 ];
}
