import * as actionTypes from './action';


const initialState = {
    isLoading: false,
    error: null,
    updateDate: null,
    status: undefined, 
    clips:[], 
    advertisingclips: [], 
    sections: [], 
    settings: {}
    
};

const ClipReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_CLIPS_SUCCESS: {
            var clips = action.payload.clips
            const sections = action.payload.sections
            //const advertisingclips = clips.filter(f => f.sections.includes("Advertising"))
            const advertisingclips = action.payload.advertisingclips
            
            
            console.log('advertisingclips in clipreducer are: ', advertisingclips)
            console.log('clips in clipreducer before are: ', clips)
             clips = clips.map( clip => {
              if ( clip.sections.length>1 && clip.sections.includes("Advertising")) { return clip;}
              if ( clip.sections.length>=1 && !clip.sections.includes("Advertising")) { return clip; }
            })
              clips = clips.filter( f => f != null)
              clips = clips.filter( f => f != undefined)
              console.log('clips in clipreducer after are: ', sections)
            
         
         /*   clips = clips.map( clip => {
              if (clip.sections.includes("Advertising")){
                let sections = clip.sections.find( c => c!="Advertising")
                clip.sections = sections
              }
              return clip
            })
            */

            return Object.assign({}, state, {
                isLoading: false,
                updateDate: Date.now(),
                status: action.payload.status, 
                clips: clips, 
                sections: sections, 
                advertisingclips: advertisingclips, 
                settings: action.payload.settings
            
            });
        }
        
        case actionTypes.GET_CLIPS_REQUEST: {
           

            return Object.assign({}, state, {
                isLoading: false,
                updateDate: Date.now(),
                clips: state.clips, 
                settings: state.settings

            });
        }

        case actionTypes.SET_CLIPS_SEARCHREQUEST: {

          var clips = action.payload.clips;
           /*
            clips = clips.map( clip => {
            //if ( clip.sections.length>1 && clip.sections.includes("Advertising")) { return clip;} // contains another section which is not "Advertising"
            //if ( clip.sections.length==1 && clip.sections[0] !="Advertising") { return clip; } // check if 
            if (clip.sections.includes("Advertising") == false ) return clip;
            else if (clip.sections.length > 1) return clip;
            
          })
          */
            clips = clips.filter( f => f != null)
            clips = clips.filter( f => f != undefined)
           
          return Object.assign({}, state, {
              isLoading: false,
              updateDate: Date.now(),
              //clips: [...state.clips]
              clips: clips, 
              settings: state.settings
          });
      }        

        case actionTypes.UPDATECLIP_LOCALSTORE: {
            const clip = state.clips.find(cl => cl.uuid == action.payload.clip.uuid);
            Object.assign(clip, action.payload.clip);
            return Object.assign({}, state, {
                isLoading: false,
                updateDate: Date.now(),
                clips: [...state.clips]
            });
        }


        case actionTypes.CLIP_SYNC_RESPONSE: {

            const clips = state.clips;

            return Object.assign({}, state, {
                isLoading: false,
                updateDate: Date.now(),
                clips: clips
            });
        }
        case actionTypes.CLIPS_ERROR: {
           
            return Object.assign({}, state, {
                isLoading: false,
                status: action.payload.status, 
                error: action.payload.error, 
                settings: state.settings
            });
        }
        default:
            return state;
    }
}




export default ClipReducer;
