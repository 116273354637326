import logger from 'redux-logger'
import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import storage from 'redux-persist/lib/storage'
import { persistStore, persistReducer } from 'redux-persist';

import rootReducer from './reducers/index';
import rootSaga from './utils/saga';

const sagaMiddleware = createSagaMiddleware();

const persistConfig = {
  key: 'root',
  storage,
  //timeout: null,
};


const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(
  persistedReducer,
  applyMiddleware(sagaMiddleware, logger),  
);
sagaMiddleware.run(rootSaga);

export default store;
export const persistor = persistStore(store);
