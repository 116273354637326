
export const AUTH_SIGNIN_REQUEST = 'AUTH_SIGNIN_REQUEST';
export const AUTH_SIGNUP_REQUEST = 'AUTH_SIGNUP_REQUEST';

export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_ERROR = 'AUTH_ERROR'
export const AUTH_SIGNOUT = 'AUTH_SIGNOUT'
export const PWD_RESET_REQUEST = 'PWD_RESET_REQUEST'
export const PWD_ERROR = 'PWD_ERROR'
export const PWD_SUCCESS = 'PWD_SUCCESS'

export const authSigninRequest = (payload) => ({
    type: AUTH_SIGNIN_REQUEST,
    payload
})

export const resetPasswordRequest = (payload) => ({
    type: PWD_RESET_REQUEST,
    payload
})

export const pwdResetSuccess = (payload) => ({
    type: PWD_SUCCESS,
    payload
})

export const pwdResetError = (error) => ({
    type: PWD_ERROR,
    error
})

export const authSignupRequest = (payload) => ({
    type: AUTH_SIGNUP_REQUEST,
    payload
})

export const authSuccess = (payload) => ({
    type: AUTH_SUCCESS,
    payload
})

export const authError = (error) => ({
    type: AUTH_ERROR,
    error
})

export const authSignout = () => ({
    type: AUTH_SIGNOUT,
    payload: {}
})
