import React from 'react';
import {Row, Col, Container} from 'react-bootstrap';
import MovieCard from '../movie-card/movie-card.component';
//import LoaderComponent from '../../common/loader.component';

const styles = {
  movieColumn: {
    marginBottom: 20,
    
  }
}


const MovieListComponent = ({movies, sections, mode, callback,  isLoading}) => {
  
  var movieColumns = 0; 
/*
  if ( movies != null && movies.length <= 1) {
    movieColumns = movies ? movies.map(movie => (
      <Col style={styles.movieColumn} key={movie.id} xs="12" sm="auto" md="auto" lg="auto" xl="auto" >
        <MovieCard movie={movie}   />
      </Col>
    )) : null;
  } else {
  movieColumns = movies ? movies.map(movie => (
    <Col style={styles.movieColumn} key={movie.id} xs={12} sm={6} md={6} lg={4} xl={3}>
      <MovieCard movie={movie}/>
    </Col>
  )) : null;
  

}*/
if ( movies != null && movies.length <= 1  ) {
  movieColumns = movies ? movies.map(movie => (
    
    
    <Col style={styles.movieColumn} key={movie.id} xs="12" sm="6" md="6" lg="4" xl="3" >
      <MovieCard callback={callback} mode={mode} sections={sections} movie={movie} movies={movies}  />
    </Col>
    
  )) : null;
} else  {
   
movieColumns = movies!=null && movies ? movies.map(movie => (
  
  <Col style={styles.movieColumn} key={movie.id} xs="12" sm="6" md="6" lg="4" xl="3" >
    <MovieCard callback={callback} mode={mode} sections={sections} movie={movie} movies={movies}/>
  </Col>
  
)) : null;


}

  
  return (
    <Container>
    <Row>
      {movieColumns}
    </Row>
    <br/>
    </Container>
  );
}

export default MovieListComponent;
