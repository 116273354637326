import React, { Component, Fragment  } from 'react';
import MovieLinkComponent from '../../movie-browser/movie-card/movie-link.component';
import { BrowserRouter, Route, Switch, Link } from 'react-router-dom';
import joobanner from './images/Videobanner_joobster_gemeinderegional.jpg';
import AdvertisingSlider from '../AdvertisingSlider'
import { connect } from 'react-redux';
import * as action from '../../../redux/action'

class Header extends Component {




  render() {



    return (
<div>
<div className="site_container">
<div className="header_top_bar_container clearfix style_2 border">
				<div className="header_top_bar">
					<form className="search">
						<input type="text" name="s" placeholder="Search..."  className="search_input hint" />
						<input type="submit" className="search_submit" value=""/>
						<input type="hidden" name="page" value="search"/>
					</form>
					<ul className="social_icons clearfix">
						<li>
							<a target="_blank" href="http://facebook.com/joobstergmbh" className="social_icon facebook" title="facebook">
								&nbsp;
							</a>
						</li>
						<li>
							<a target="_blank" href="https://twitter.com/joobster1" className="social_icon twitter" title="twitter">
								&nbsp;
							</a>
						</li>
						<li>
							<a href="mailto:hello@joobster.at" className="social_icon mail" title="mail">
								&nbsp;
							</a>
						</li>


					</ul>
					<div className="latest_news_scrolling_list_container">
						<ul>
							<li className="category">AKTUELL</li>
							<li className="left"><a href="#"></a></li>
							<li className="right"><a href="#"></a></li>
							<li className="posts">
								<ul className="latest_news_scrolling_list">
{
  this.props.clips.map( singlemovie =>(
    <li>
      <MovieLinkComponent movie={singlemovie}/>
    </li>)
  )
}
								</ul>
							</li>


                <li className="date">
                  {
                    this.props.clips.map(singlemovie => (

                      <abbr title={singlemovie.title} className="timeago current"></abbr>
                    )
                    )

                  }
                </li>

						</ul>
					</div>
				</div>
			</div>



			<div className="header_container small">
				<div className="header clearfix">
					<div className="logo">
						<h1><Link className="nav-link" to="/home">jooNews</Link></h1>
						<h4>Regionale Video Neuheiten</h4>
					</div>

          <div >
            <AdvertisingSlider />
          </div>
          
				</div>
			</div>
</div>
</div>
    );
  }
}


const mapStateToProps = (state) => {
  return {
		clips: state.clipReducer.clips,
  }
};


const mapDispatchToProps = (dispatch) => ({
    getClipsRequest: (payload) => dispatch(action.getClipsRequest(payload))
});

export default  connect(mapStateToProps, mapDispatchToProps)(Header)

