import React from 'react';
import PropTypes from 'prop-types';import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

import Select from '@material-ui/core/Select';
import { withStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import Category from '@material-ui/icons/Category';
import Badge from '@material-ui/core/Badge';
import {Container, Col, Row} from 'react-bootstrap'
import { connect } from 'react-redux';
import * as action from '../../redux/action'

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center'    
    
  },
  formControl: {
    margin: 20,
    minWidth: 300,
    maxWidth: 400,
    borderRadius: 0,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),    
 
   
  },
  dropdownStyle: {
    width: 300,
    background: 'pink',
    color: 'black',
      'box-shadow': '0 0 50px rgba(0, 0, 0, .7)'
    
  },  
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: theme.spacing.unit / 4,
  },
  margin: {
    margin: theme.spacing(1),
    //  marginTop: '100'
  },  
  selectEmpty: {
    marginTop: theme.spacing(2),
  }, 
  customBadge: props => ( {
    backgroundColor: props.badgecolor,
    color: "white"
  
  }    
  )
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 800,
      border: "1px solid black",

    },
  },
};


const BootstrapInput = withStyles(theme => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(4),
    },
  },
  input: {
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
    
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase);


class MovieSections extends React.Component {

  
 constructor(props)  {
    super(props)

this.state = {
  sections: []//this.props.sections.map( s => s.name),
}


}

/*
async componentDidMount(){

  var cats = []
  var clipid = this.getUrlVars()["id"]
  if (clipid) {
    console.log("in moviesections with id: ",clipid );
    cats =  await this.getCategorie(); 
    cats = cats.clips.filter( item => item.id == clipid)[0].sections
}

if (this.getUrlVars()["cat"]) {
  console.log("in moviesections with cats: ",this.getUrlVars()["cat"].split(","));
  cats = this.getUrlVars()["cat"].split(",")
}

this.setState({sections: cats})

}

*/

getUrlVars() {
  var vars = {};
  var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m,key,value) {
      vars[key] = value;
  });
  return vars;
}


  handleChange = event => {
    this.setState({ sections: event.target.value });    
    console.log('choosen value in moviesections is: ', event.target.value)
    this.props.exchangeFunc(event.target.value);
  };


/*
  getCategorie = async () => {
    const retval = await fetch('https://api2.joobster.at/portal/group/5b4ea9901bb6981a06c5be80/clip');
    //const retval = await fetch('https://portal.joobster.at/portal/group/5b4ea9901bb6981a06c5be80/clip');
    //console.log("getCategorie....", retval.json())
    return retval.json(); 
    //return fetch('https://portal.joobster.at/portal/group/5b4ea9901bb6981a06c5be80/clip'); 
   }
   */




  render() {
    const { classes, theme } = this.props;

    const localsections =   [];
    if (this.props.sections) this.props.sections.map( section => {if (section.name != "Advertising") localsections.push(section.name) })  
    

    
    return (
      <div className={classes.root}>
        {this.props.sections ? <div>
        <Badge 
                            classes={{ badge: classes.customBadge }}
          style={{ marginTop: '45px'}} badgeContent={localsections.length} >
          <Category />
        </Badge>
        <FormControl className={classes.formControl}>
 
         

          <Select 
            displayEmpty={true}
            multiple
            className={styles.selectEmpty}
            value={this.state.sections}
            onChange={this.handleChange}            
            input={<BootstrapInput id="select-multiple" />}

          >
            <MenuItem value="" disabled>
            Kategorie
          </MenuItem>
            {localsections.map(cat => (
              <MenuItem
                key={cat}
                value={cat}
                style={{
                  fontWeight:
                    localsections.indexOf(cat) === -1
                      ? theme.typography.fontWeightRegular
                      : theme.typography.fontWeightMedium,
                }}
              >
                {cat}
              </MenuItem>
            ))}
          </Select>
         
        </FormControl>
        </div> : null
        }
      </div>
    );
  }
}

MovieSections.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};



const mapStateToProps = (state) => {
  return {
    clips: state.clipReducer.clips,
    sections: state.clipReducer.sections, 
    advertisingclips: state.clipReducer.advertisingclips
  }
};


const mapDispatchToProps = (dispatch) => ({
    getClipsRequest: (payload) => dispatch(action.getClipsRequest(payload)), 
    setSearchRequest: (payload) => dispatch(action.setSearchRequest(payload))
});

export default   withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(MovieSections))

